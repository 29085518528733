import { get, post, put, Delete } from "../axios";

// 视频列表-视频分类（用于视频列表查询和新增编辑）
export const videoListClassify = (params) =>
  get("/web/video/list/classify", params);

// 视频列表-权益列表（用于视频列表查询和新增编辑）
export const videoEquityClassifyList = (params) =>
  get("/web/video/equity/classify/list", params);

// 视频列表-列表
export const videoManageList = (params) =>
  get("/web/video/manage/list", params);

// 视频列表-新增
export const videoManageAdd = (params) => post("/web/video/manage/add", params);

// 视频列表-编辑
export const videoManageUpdate = (params) =>
  put("/web/video/manage/update", params);

// 视频列表-删除
export const videoManageDelete = (id) =>
  Delete("/web/video/manage/delete/" + id, "");

// 视频列表-详情
export const videoManageView = (id) => get("/web/video/manage/view/" + id, "");

// 视频列表-首页推荐-状态编辑
export const videoManageHomeUpdate = (params) =>
  put("/web/video/manage/home/update", params);
